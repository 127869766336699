import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { motion } from 'framer-motion'

import { classes } from '../../utils/helpers'
import useTranslations, { useLocale } from '../useTranslations'
import { useReveal } from '../../hooks/useIntersection'
// import Link from '../LocalizedLink'

import styles from './OpenPositions.module.sass'

import Arrow from '../../images/icons/arrow.svg'

const OpenPositions = () => {
  const [observerRef, inViewport] = useReveal()
  const { openPositions, noPositions } = useTranslations()
  const locale = useLocale()

  const rawData = useStaticQuery(dataQuery)

  const data = rawData.allFile.edges
    .filter(({ node }) => node.childMarkdownRemark && node.childMarkdownRemark.frontmatter)
    .map(({ node }) => ({
      ...node.childMarkdownRemark.frontmatter,
      base: node.base
    }))

  return (
    <div className={styles.Wrapper}>
      <div className={classes(styles.Container, !data.length && styles.empty)} ref={observerRef}>
        <h3>{openPositions}</h3>
        {data.length ? (
          <motion.ul
            initial="hide"
            animate={inViewport ? 'show' : 'hide'}
          >
            {data.map(({ base, title, place, image }, index) =>
              <motion.li
                key={base}
                variants={revealChildAnimation}
                custom={index}
                style={{
                  backgroundImage: `linear-gradient(0deg, white 0%, white 25%, rgba(255, 255, 255, 0) 60%), url(/${image})`,
                  backgroundSize: 'cover'
                }}
              >
                <span>{place}</span>
                <div>
                  <Link to={`/pozice/${base.split('.')[0]}`}>
                    {title}
                  </Link>
                </div>
              </motion.li>
            )}
          </motion.ul>
        ) : (
          <p className={styles.empty}>{noPositions}</p>
        )}
      </div>
    </div>
  )
}

export default OpenPositions

const revealAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.5
    }
  },
}

const revealChildAnimation = {
  hide: {
    opacity: 0,
    x: 20
  },
  show: i => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.15
    }
  }),
}

const dataQuery = graphql`
  query HeaderQuery {
    allFile(
      filter: {
        sourceInstanceName: {eq: "positions"},
        childMarkdownRemark: {frontmatter: {hide: {ne: true}}}
      }
    ) {
      edges {
        node {
          base
          extension
          childMarkdownRemark {
            frontmatter {
              title
              place
              image
            }
          }
        }
      }
    }
  }
`
