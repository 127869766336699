import React from 'react'

import { classes } from '../../utils/helpers'
import useTranslations from '../useTranslations'
import useMenu from '../useMenu'
import LocalizedLink from '../LocalizedLink'

import shared from '../../styles/components.module.sass'
import styles from './ContactSection.module.sass'

const ContactCTA = () => {
  const { footerCTA, contactUs } = useTranslations()
  const [, contact] = useMenu()

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <h3>{footerCTA}</h3>
        <LocalizedLink to={contact.link} className={classes(shared.ButtonLight, styles.Button)}>
          {contactUs}
        </LocalizedLink>
      </div>
    </div>
  )
}

export default ContactCTA
