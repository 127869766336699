import React from 'react'
import { graphql } from 'gatsby'

import { classes } from '../utils/helpers'
import SEO from '../components/seo'
import OpenPositions from '../components/OpenPositions'
import ContactSection from '../components/ContactSection'

import components from '../styles/components.module.sass'
import styles from './Position.module.sass'

const Position = props => {
  const { title, description, image, place, date } = props.pageContext.data.frontmatter
  const { html } = props.pageContext.data

  return (
    <>
      <SEO
        title={title}
        description={description}
        image={image}
      />
      <header className={styles.HeaderWrapper}>
        <div className={classes(components.Container, styles.HeaderContainer)}>
          <div className={styles.Image}>
            <img src={`/${image}`} alt={`Otevřená pozice ${title}`} />
          </div>
          <div className={styles.Headings}>
            <h1>Hledáš práci?</h1>
            <h2>{title}</h2>
            <h3>{place}</h3>
            <div>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </header>
      <section className={styles.Content}>
        <div className={styles.ContentContainer}>
          <div dangerouslySetInnerHTML={{__html: html }}/>
          <p className={styles.ContactInfo}>
            V případě zájmu pište<br/>
            na jar.pekar@gmail.com<br/>
            a volejte +420 777 999 222
          </p>
        </div>
      </section>
      <OpenPositions />
      <ContactSection />
    </>
  )
}

export default Position
