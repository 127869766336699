import { useEffect, useState, useRef } from 'react'

const useIntersection = (
  reset = false,
  options = {
    root: null,
    threshold: 0.6,
    rootMargin: '0px'
  }
) => {
  const targetRef = useRef(null)
  const [observer, setObserver] = useState(null)
  const [intersectionObserverEntry, setIntersectionObserverEntry] = useState(null)
  const isIntersecting = intersectionObserverEntry ? intersectionObserverEntry.isIntersecting : false

  if (typeof IntersectionObserver !== 'function') {
    return [targetRef, true, null]
  }

  useEffect(() => {
    if (!observer) {
      const handler = entries => { setIntersectionObserverEntry(entries[0]) }
      const newObserver = new IntersectionObserver(handler, options)
      setObserver(newObserver)
      return () => { newObserver.disconnect() }
    }
  }, [observer, setObserver])

  useEffect(() => {
    if (targetRef.current && observer) {
      if (reset) {
        observer.unobserve(targetRef.current)
        return () => {}
      }

      observer.observe(targetRef.current)

      return () => {
        setIntersectionObserverEntry(null)
      }
    }
    return () => {}
  }, [targetRef.current, observer, reset])

  return reset
    ? [targetRef, true, null]
    : [targetRef, isIntersecting, intersectionObserverEntry]
}

export default useIntersection

export const useReveal = (options) => {
  const [revealed, setRevealed] = useState(false)
  const [targetRef, isIntersecting, intersection] = useIntersection(revealed, options)

  useEffect(() => {
    if (!revealed && isIntersecting) {
      setRevealed(true)
    }
  }, [revealed, isIntersecting])

  return [targetRef, isIntersecting, intersection]
}
